import { Controller } from "stimulus"

import Stimulus from "../utils/stimulus"

// forces links to start with secure `https://`
// forces internal links to start with `/` for Turbo use
// forces target blank for external links

class FixAnchors extends Controller {
  connect() {
    const selector = `a[href^="https://"], a[href^="http://"], a[href^="www."]`

    this.element.querySelectorAll(selector).forEach((a) => {
      let href = a.href

      if (href.startsWith(`http://`)) {
        href = href.replace(`http://`, `https://`)
      } else if (href.startsWith(`www.`)) {
        href = `https://www.${a.href}`
      }

      if (href.startsWith(`https://${window.APP_HOST}`)) {
        href = href.replace(`https://${window.APP_HOST}`, ``)
      } else if (href.startsWith(`https://www.${window.APP_HOST}`)) {
        href = href.replace(`https://www.${window.APP_HOST}`, ``)
      }

      if (!href) href = `/`

      if (!href.startsWith(`/`)) {
        a.target = `_blank`
        a.rel = `${a.rel || ``} noreferrer noopener`.trim()
      }

      a.href = href
    })
  }
}

Stimulus.register(`fix-anchors`, FixAnchors)
