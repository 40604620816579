// TODO: check if some the most common call could be cached into localStorage, then alter Search component accordingly

import { uniqBy } from "lodash"

const LANGUAGE = document.documentElement.lang

const CACHE = {}

const fetchApi = (query, method = `GET`, body = null) => {
  return window
    .fetch(`${window.APP_API_BASE_URL}${query}`, {
      method,
      body: body ? JSON.stringify(body) : null,
      redirect: `follow`,
      headers: {
        Accept: `application/vnd.api+json`,
        "Content-Type": `application/vnd.api+json`,
        "X-Naturkartan-API-Key": window.APP_API_KEY,
      },
    })
    .then((response) => {
      if (!response.ok) throw Error(response.statusText)
      return response
    })
    .then((response) => response.json())
    .catch((error) => {
      throw Error(error)
    })
}

const fetchPaginatedApi = (query) => {
  return new Promise((resolve, reject) => {
    const pages = []

    const fetch = (query) => {
      return new Promise((resolve, reject) => {
        return fetchApi(query)
          .then((page) => {
            pages.push(page)

            if (page.links && page.links.next && page.links.next != query)
              return resolve(fetch(page.links.next))

            return resolve(true)
          })
          .catch(reject)
      })
    }

    return fetch(query)
      .then(() => resolve(pages))
      .catch(reject)
  })
}

const fetchApiCategories = ({ language = LANGUAGE } = {}) => {
  return fetchPaginatedApi(`/v3/categories`).then((pages) => {
    const categories = []

    pages.forEach((page) =>
      page.data.forEach(
        (c) =>
          c.attributes.legacy_type != `animal` &&
          categories.push({
            id: String(c.id),
            slug: c.attributes.slug,
            icon: c.attributes.icon,
            title: c.attributes.name[language] || c.attributes.name.sv,
            aliases: c.attributes.aliases && c.attributes.aliases[language],
          })
      )
    )

    return uniqBy(categories, `id`)
  })
}

const fetchApiOrganizations = ({ language = LANGUAGE } = {}) => {
  const cacheKey = `organizations-${language}`
  if (CACHE[cacheKey]) return new Promise((resolve) => resolve(CACHE[cacheKey]))

  return fetchPaginatedApi(`/v3/organizations`).then((pages) => {
    const organizations = []

    pages.forEach((page) =>
      page.data.forEach((o) =>
        organizations.push({
          id: String(o.id),
          title: o.attributes.name[language] || o.attributes.name.sv,
        })
      )
    )

    CACHE[cacheKey] = organizations
    return organizations
  })
}

const fetchApiGuides = ({
  language = LANGUAGE,
  includeHidden = false,
} = {}) => {
  return fetchPaginatedApi(`/v3/guides`).then((pages) => {
    let guides = []

    pages.forEach((page) =>
      page.data.forEach((g) =>
        guides.push({
          id: String(g.id),
          title: g.attributes.name[language] || g.attributes.name.sv,
          slug: g.attributes.slug,
          hidden: g.attributes.hidden,
        })
      )
    )

    if (!includeHidden) guides = guides.filter((g) => !g.hidden)

    return guides
  })
}

const fetchApiCounties = ({ language = LANGUAGE } = {}) => {
  const cacheKey = `counties-${language}`
  if (CACHE[cacheKey]) return new Promise((resolve) => resolve(CACHE[cacheKey]))

  return fetchPaginatedApi(`/v3/counties`).then((pages) => {
    const counties = []

    pages.forEach((page) =>
      page.data.forEach((c) =>
        counties.push({
          id: String(c.id),
          title: c.attributes.name[language] || c.attributes.name.sv,
          countryId: c?.relationships?.country?.data?.id,
        })
      )
    )

    CACHE[cacheKey] = counties
    return counties
  })
}

const fetchApiMunicipalities = ({ language = LANGUAGE } = {}) => {
  const cacheKey = `municipalities-${language}`
  if (CACHE[cacheKey]) return new Promise((resolve) => resolve(CACHE[cacheKey]))

  return fetchPaginatedApi(`/v3/municipalities`).then((pages) => {
    const municipalities = []

    pages.forEach((page) =>
      page.data.forEach((c) =>
        municipalities.push({
          id: String(c.id),
          title: c.attributes.name[language] || c.attributes.name.sv,
          countyId: c?.relationships?.county?.data?.id,
        })
      )
    )

    CACHE[cacheKey] = municipalities
    return municipalities
  })
}

const fetchApiCities = ({ language = LANGUAGE } = {}) => {
  const cacheKey = `cities-${language}`
  if (CACHE[cacheKey]) return new Promise((resolve) => resolve(CACHE[cacheKey]))

  return fetchPaginatedApi(`/v3/cities`).then((pages) => {
    const cities = []

    pages.forEach((page) =>
      page.data.forEach((c) =>
        cities.push({
          id: String(c.id),
          title: c.attributes.name[language] || c.attributes.name.sv,
          municipalityId: c?.relationships?.municipality?.data?.id,
        })
      )
    )

    CACHE[cacheKey] = cities
    return cities
  })
}

export {
  fetchApiCategories,
  fetchApiOrganizations,
  fetchApiGuides,
  fetchApiCounties,
  fetchApiMunicipalities,
  fetchApiCities,
}
