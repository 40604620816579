import { Controller } from "stimulus"

import Stimulus from "../../../common/utils/stimulus"

// form modal

class SitePageQuestionsFormModal extends Controller {
  static values = { frameId: String }
  static targets = [`form`, `button`]

  connect() {
    document.documentElement.addEventListener(
      `turbo:submit-end`,
      this.turboSubmitEnd
    )
  }

  disconnect() {
    document.documentElement.removeEventListener(
      `turbo:submit-end`,
      this.turboSubmitEnd
    )
  }

  submit() {
    this.formTarget.requestSubmit()
  }

  disableButton() {
    this.buttonController.setDisabled(true)
  }

  turboSubmitEnd = (e) => {
    if (e.target != this.formTarget) return

    this.buttonController.setDisabled(false)

    if (e.detail.formSubmission.result.success) {
      this.formTarget.reset()
      this.modalController.hide()
      const listFrameEl = document.getElementById(this.frameIdValue)
      if (listFrameEl) {
        if (listFrameEl.disabled) listFrameEl.disabled = false
        else listFrameEl.reload()
        listFrameEl.scrollIntoView({ block: `nearest`, behavior: `smooth` })
      }
    }
  }

  get buttonController() {
    return this.application.getControllerForElementAndIdentifier(
      this.buttonTarget,
      `button`
    )
  }

  get modalController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      `modal`
    )
  }
}

Stimulus.register(`site-page-questions-form-modal`, SitePageQuestionsFormModal)
