import { Controller } from "stimulus"
import { isEqual } from "lodash"

import Stimulus from "../utils/stimulus"

class Button extends Controller {
  static values = { form: String }

  static targets = [`text`]

  #textDefault

  connect() {
    this.#textDefault = this.textTarget.innerHTML

    if (this.formValue && this.formValue != `false`) {
      document.addEventListener(`submit`, this.docSubmit)
      document.addEventListener(`turbo:submit-start`, this.docTurboSubmitStart)
      document.addEventListener(`turbo:submit-end`, this.docTurboSubmitEnd)
      document.addEventListener(`turbo:before-visit`, this.docTurboBeforeVisit)
    }
  }

  disconnect() {
    if (this.formValue && this.formValue != `false`) {
      document.removeEventListener(`submit`, this.docSubmit)
      document.removeEventListener(`turbo:submit-start`, this.docTurboSubmitStart) // eslint-disable-line prettier/prettier
      document.removeEventListener(`turbo:submit-end`, this.docTurboSubmitEnd) // eslint-disable-line prettier/prettier
      document.removeEventListener(`turbo:before-visit`, this.docTurboBeforeVisit) // eslint-disable-line prettier/prettier
    }
  }

  // event handlers

  docSubmit = (e) => {
    this.setLoadingOnTurboSubmit(e, true)
  }

  docTurboSubmitStart = (e) => {
    this.setLoadingOnTurboSubmit(e, true)
  }

  docTurboSubmitEnd = (e) => {
    this.setLoadingOnTurboSubmit(e, false)
  }

  docTurboBeforeVisit = () => {
    if (this.formValue && this.formValue != `false`) this.setLoading(false)
  }

  setLoadingOnTurboSubmit = (e, loading) => {
    if (!e.defaultPrevented && this.formValue && this.formValue != `false`) {
      if (this.formValue == `true`) {
        if (this.element.href) {
          const url = new URL(this.element.href)
          if (url.origin + url.pathname == e.target.action) {
            const formParams = {}
            new FormData(e.target).forEach((v, k) => (formParams[k] = v))

            const urlParams = {}
            url.searchParams.forEach((v, k) => (urlParams[k] = v))

            if (isEqual(formParams, urlParams)) this.setLoading(loading)
          }
        } else {
          if (e.target == this.element.closest(`form`)) this.setLoading(loading)
        }
      } else {
        if (e.target == document.getElementById(this.formValue))
          this.setLoading(loading)
      }
    }
  }

  // helpers

  setDisabled = (disabled = true) => {
    this.element.disabled = disabled
    this.element.classList.toggle(`button--disabled`, disabled)
  }

  setLoading = (loading = true) => {
    this.setDisabled(loading)
    this.element.classList.toggle(`button--loading`, loading)
  }

  setText = (text = this.#textDefault) => {
    this.textTarget.innerHTML = text
  }

  setTextColor = (color) => {
    this.element.style.setProperty(`--text-color`, `var(--color-${color})`)
  }

  setFillColor = (color) => {
    this.element.style.setProperty(`--fill-color`, `var(--color-${color})`)
  }

  setOutlineColor = (color) => {
    this.element.style.setProperty(`--outline-color`, `var(--color-${color})`)
  }
}

Stimulus.register(`button`, Button)
