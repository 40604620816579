import { Controller } from "stimulus"

import Stimulus from "../utils/stimulus"

class Flash extends Controller {
  static values = { permanent: Boolean }
  #to = null

  connect() {
    if (!this.permanentValue) {
      this.#to = window.setTimeout(this.hide, 5000)
      window.addEventListener(`click`, this.hide)
    }
  }

  disconnect() {
    if (this.#to) window.clearTimeout(this.#to)
    if (!this.permanentValue) window.removeEventListener(`click`, this.hide)
  }

  hide = () => {
    this.element.remove()
  }
}

Stimulus.register(`flash`, Flash)
