import { Controller } from "stimulus"

import Stimulus from "../../common/utils/stimulus"
import { scrollIntoView } from "../../common/utils/scroll"

class GuidePage extends Controller {
  static targets = [`map`]

  mapTargetConnected() {
    window.setTimeout(() => {
      this.mapController.addReceiveHandler(this.mapReceive)
    }, 10)
  }

  mapReceive = (message) => {
    switch (message.method) {
      case `loaded`: {
        if (window.location.hash == `#${this.mapTarget.id}`)
          this.enterMapFullscreen()
        break
      }

      case `setQuery`: {
        this.mapQueryWasSet(message.data)
        break
      }
    }
  }

  enterMapFullscreen = () => {
    return new Promise((resolve, reject) => {
      scrollIntoView(this.mapTarget, {
        behavior: `smooth`,
        block: `center`,
      }).then(() => {
        this?.mapController.setFullscreen(true).then(resolve)
      })
    })
  }

  goToMap = (e) => {
    if (!this.hasMapTarget) return
    if (e) e.preventDefault()

    let query = ``
    if (e?.params?.query) query = e.params.query
    else if (e?.currentTarget?.dataset?.categoryButtonId)
      query = `category=${e.currentTarget.dataset.categoryButtonId}`

    this.enterMapFullscreen().then(() =>
      this.mapController.setQueryWithDefault(query)
    )
  }

  mapQueryWasSet = (query) => {
    const urlTypes = this.mapTarget.dataset.urlTypes.split(`,`)
    const params = {}

    query.forEach((q) => {
      if (urlTypes.includes(q.type)) {
        params[q.type] = params[q.type] || []
        params[q.type].push(q.value)
      }
    })

    let url = Object.entries(params)
      .map(([k, v]) => `${k}=${v.join(`,`)}`)
      .join(`&`)

    if (url && this.mapTarget.id) url += `#${this.mapTarget.id}`

    window.history.replaceState(null, null, `?${url}`)
  }

  get mapController() {
    if (!this.hasMapTarget) return null

    return this.application.getControllerForElementAndIdentifier(
      this.mapTarget,
      `map-new`
    )
  }
}

Stimulus.register(`guide-page`, GuidePage)

// hero categories

class GuidePageHeroCategories extends Controller {
  static targets = [`carousel`, `navPrev`, `navNext`]

  carouselTargetConnected() {
    window.setTimeout(() => {
      this.carouselController.addScrollEndHandler(this.scrollEnded)
      this.scrollEnded()
    }, 10)
  }

  next() {
    this.carouselController.next()
  }

  prev() {
    this.carouselController.prev()
  }

  scrollEnded = () => {
    this.navPrevTarget.disabled = false
    this.navNextTarget.disabled = false

    if (this.carouselController.isBeginning) this.navPrevTarget.disabled = true
    if (this.carouselController.isEnd) this.navNextTarget.disabled = true
  }

  get carouselController() {
    if (!this.hasCarouselTarget) return null

    return this.application.getControllerForElementAndIdentifier(
      this.carouselTarget,
      `carousel`
    )
  }
}

Stimulus.register(`guide-page-hero-categories`, GuidePageHeroCategories)
