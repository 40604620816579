import { Controller } from "stimulus"
import turfBbox from "@turf/bbox"
import turfBooleanWithin from "@turf/boolean-within"
import turfBuffer from "@turf/buffer"
import { point as turfPoint } from "@turf/helpers"

import Stimulus from "../utils/stimulus"
import { swedenGeoPolygonFeature } from "../../common/config/geo"

class ExploreBounds extends Controller {
  connect() {
    const center = window.APP_USER_LOCATION

    this.element.addEventListener(`click`, (e) => {
      if (center && center.length == 2) {
        const point = turfPoint(center.reverse())
        if (turfBooleanWithin(point, swedenGeoPolygonFeature)) {
          e.preventDefault()
          const bbox = turfBbox(turfBuffer(point, 20, { steps: 1 })).join(`,`)
          const url = new window.URL(this.element.href)
          url.searchParams.set(`bounds`, bbox)
          window.location.href = url.toString()
        }
      }
    })
  }
}

Stimulus.register(`explore-bounds`, ExploreBounds)
