import { Controller } from "stimulus"
import A11yDialog from "a11y-dialog"
import { enablePageScroll, disablePageScroll } from "scroll-lock"
import { delegateEventListener } from "@outdoormap/frontend-utils/js/event"

import Stimulus from "../utils/stimulus"
import modal from "../utils/modal"

const getWinHash = () => window.location.hash.replace(`#`, ``)

const LOADED = {
  ids: [],
  is(id) {
    return this.ids.includes(id)
  },
  add(id) {
    this.ids.push(id)
  },
  remove(id) {
    this.ids = this.ids.filter((i) => i != id)
  },
  any() {
    return !!this.ids.length
  },
}

// modal

class Modal extends Controller {
  static values = { id: String, scrollLock: Boolean }

  #a11ydialog = null

  connect() {
    document.addEventListener(`turbo:before-cache`, this.hide)
    this.modalLoaderController.modalConnected(this)
    this.#a11ydialog = new A11yDialog(this.element)
    this.#a11ydialog.show()
    this.#a11ydialog.on(`hide`, () => this.element.remove())

    if (this.scrollLockValue) disablePageScroll(this.element)
  }

  disconnect() {
    document.removeEventListener(`turbo:before-cache`, this.hide)
    if (this.scrollLockValue) enablePageScroll(this.element)
    if (this.modalLoaderController)
      this.modalLoaderController.modalDisconnected()
  }

  hide() {
    this.#a11ydialog.hide()
  }

  get modalLoaderController() {
    return modal.getLoaderController(this.idValue)
  }
}

Stimulus.register(`modal`, Modal)

// loader

class ModalLoader extends Controller {
  static values = {
    id: String,
    history: Boolean,
    autoshow: Boolean,
  }

  #modalController = null
  #disconnected = false
  #onHideFns = new Set()

  connect() {
    this.#disconnected = false

    if (this.autoshowValue) this.show()

    if (this.historyValue) {
      window.addEventListener(`popstate`, this.winPopstateChange)
      if (!this.autoshowValue && getWinHash() == this.idValue) this.show()
    }

    delegateEventListener(
      `[data-modal-show="${this.idValue}"]`,
      `click`,
      (e) => {
        if (!this.#disconnected) {
          e.preventDefault()
          this.show()
        }
      }
    )

    delegateEventListener(
      `[data-modal-hide="${this.idValue}"]`,
      `click`,
      (e) => {
        if (!this.#disconnected) {
          e.preventDefault()
          this.hide()
        }
      }
    )
  }

  disconnect() {
    this.hide()

    if (this.historyValue)
      window.removeEventListener(`popstate`, this.winPopstateChange)

    this.#disconnected = true
  }

  show = () => {
    if (LOADED.is(this.idValue)) return
    LOADED.ids.forEach((id) => modal.hide(id))
    LOADED.add(this.idValue)
    document.body.appendChild(this.element.content.cloneNode(true))

    if (this.historyValue && getWinHash() != this.idValue)
      window.history.pushState(null, null, `#${this.idValue}`)
  }

  hide = () => {
    if (!LOADED.is(this.idValue)) return
    this.#modalController.hide()
  }

  onHide = (fn) => {
    this.#onHideFns.add(fn)
  }

  modalConnected(controller) {
    this.#modalController = controller
  }

  modalDisconnected() {
    LOADED.remove(this.idValue)

    if (this.historyValue && getWinHash() == this.idValue)
      window.history.pushState(null, null, ` `)

    this.#onHideFns.forEach((fn) => {
      this.#onHideFns.delete(fn)
      fn()
    })
  }

  winPopstateChange = () => {
    if (getWinHash() == this.idValue) {
      this.show()
    } else if (LOADED.is(this.idValue)) {
      this.#modalController.hide()
    }
  }
}

Stimulus.register(`modal-loader`, ModalLoader)
