import { Controller } from "stimulus"

import Stimulus from "../utils/stimulus"

class Header extends Controller {
  static values = {
    search: Boolean,
    searchToggled: Boolean,
    user: Boolean,
    admin: Boolean,
    mobileExpanded: Boolean,
  }
  static targets = [`user`, `admin`, `search`]

  connect() {
    document.addEventListener(`click`, this.docClick, { passive: true })
  }

  disconnect() {
    document.removeEventListener(`click`, this.docClick, { passive: true })
    document.documentElement.classList.remove(
      `html--header-disable-mobile-scroll`
    )
  }

  toggleUser(e) {
    e.preventDefault()
    this.userValue = !this.userValue
  }

  toggleAdmin(e) {
    e.preventDefault()
    this.adminValue = !this.adminValue
  }

  toggleSearch(e) {
    e.preventDefault()
    this.searchValue = !this.searchValue
    this.searchToggledValue = true
    this.mobileExpandedValue = false
    this.search.doFocusInput()
    window.scrollTo({ top: 0 })
    document.documentElement.classList.toggle(
      `html--header-disable-mobile-scroll`,
      this.searchValue
    )
  }

  hideSearch() {
    document.documentElement.classList.remove(
      `html--header-disable-mobile-scroll`,
      this.searchValue
    )
  }

  toggleMobileExpanded() {
    this.mobileExpandedValue = !this.mobileExpandedValue
    window.scrollTo({ top: 0 })
    document.documentElement.classList.toggle(
      `html--header-disable-mobile-scroll`,
      this.mobileExpandedValue
    )
  }

  docClick = (e) => {
    if (
      this.userValue &&
      this.hasUserTarget &&
      !this.userTarget.contains(e.target)
    )
      this.userValue = false

    if (
      this.adminValue &&
      this.hasAdminTarget &&
      !this.adminTarget.contains(e.target)
    )
      this.adminValue = false
  }

  get search() {
    return this.application.getControllerForElementAndIdentifier(
      this.searchTarget,
      `search`
    )
  }
}

Stimulus.register(`header`, Header)
