import { Controller } from "stimulus"

import Stimulus from "../../../common/utils/stimulus"
import { fetchSiteApi } from "../../../common/utils/site-api"

class SitePageToolbar extends Controller {
  static values = { favouriteApi: String }
  static targets = [`favourite`, `unfavourite`, `save`, `unsave`]

  favourite() {
    this.favouriteTarget.classList.add(`hidden`)
    this.unfavouriteTarget.classList.remove(`hidden`)

    fetchSiteApi({
      url: this.favouriteApiValue,
      method: `POST`,
    })
  }

  unfavourite() {
    this.favouriteTarget.classList.remove(`hidden`)
    this.unfavouriteTarget.classList.add(`hidden`)

    fetchSiteApi({
      url: this.favouriteApiValue,
      method: `DELETE`,
    })
  }

  toggleSave(value) {
    if (value) {
      this.saveTarget.classList.add(`hidden`)
      this.unsaveTarget.classList.remove(`hidden`)
    } else {
      this.saveTarget.classList.remove(`hidden`)
      this.unsaveTarget.classList.add(`hidden`)
    }
  }
}

Stimulus.register(`site-page-toolbar`, SitePageToolbar)
