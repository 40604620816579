import { Controller } from "stimulus"
import { debounce } from "lodash"

import Stimulus from "../utils/stimulus"

const DOC_SCROLL_PADDING_TOP = window.getComputedStyle(
  document.documentElement
).scrollPaddingTop

const HAS_DOC_SCROLL_PADDING_TOP = !!parseInt(DOC_SCROLL_PADDING_TOP)

class Navbar extends Controller {
  static values = { sticky: Boolean, stuck: Boolean }

  #observer = null

  connect() {
    if (this.stickyValue) {
      this.#observer = new IntersectionObserver(this.intersectionCallback, {
        threshold: [1],
      })

      this.#observer.observe(this.element)
    }
  }

  disconnect() {
    this.#observer?.disconnect()
  }

  intersectionCallback = debounce(
    ([e]) => (this.stuckValue = e.intersectionRatio < 1),
    100
  )

  stuckValueChanged() {
    if (HAS_DOC_SCROLL_PADDING_TOP) {
      document.documentElement.style.setProperty(
        `--body-scroll-padding-top`,
        this.stuckValue
          ? `calc(${
              this.element.getBoundingClientRect().height
            }px + ${DOC_SCROLL_PADDING_TOP})`
          : null
      )
    }
  }
}

Stimulus.register(`navbar`, Navbar)
