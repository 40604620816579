import { Controller } from "stimulus"
import { enablePageScroll, disablePageScroll } from "scroll-lock"

import Stimulus from "../utils/stimulus"

class ConfirmModal extends Controller {
  static targets = [`body`, `cancelButton`]

  connect() {
    document.addEventListener(`turbo:before-cache`, this.hide)
    disablePageScroll(this.bodyTarget)
    this.cancelButtonTarget.focus()
  }

  disconnect() {
    document.removeEventListener(`turbo:before-cache`, this.hide)
    enablePageScroll(this.bodyTarget)
  }

  hide = () => {
    this.modalController.hide()
  }

  hideByOutside(e) {
    if (e.currentTarget === e.target) this.modalController.hide()
  }

  get modalController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      `modal`
    )
  }
}

Stimulus.register(`confirm-modal`, ConfirmModal)
