const swedenGeoPolygonFeature = {
  type: `Feature`,
  properties: {},
  geometry: {
    type: `Polygon`,
    coordinates: [
      [
        [22.543945312499996, 64.66151739623564],
        [24.08203125, 65.47650756256367],
        [24.345703125, 66.31986144668052],
        [24.08203125, 68.15520923883976],
        [21.181640624999996, 69.11561106499448],
        [19.86328125, 69.24057862839378],
        [19.775390625, 68.5443150407769],
        [18.28125, 68.73638345287264],
        [16.875, 68.17155518732503],
        [15.952148437499998, 67.57571741708057],
        [15.952148437499998, 67.16995497083367],
        [14.1943359375, 66.14274269640396],
        [14.150390625, 65.47650756256367],
        [13.18359375, 64.73664139557683],
        [13.53515625, 64.37794095121995],
        [12.3046875, 64.09140752262307],
        [11.42578125, 63.174193604205094],
        [11.7333984375, 61.03701223240187],
        [10.72265625, 59.06315402462662],
        [11.77734375, 56.8249328650072],
        [12.65625, 54.97761367069628],
        [14.1943359375, 55.15376626853556],
        [16.171875, 55.55349545845371],
        [19.423828125, 57.088515327886505],
        [19.86328125, 59.489726035537075],
        [18.544921875, 60.823494332539646],
        [18.369140624999996, 62.186013857194226],
        [21.2255859375, 63.60721668033077],
        [22.543945312499996, 64.66151739623564],
      ],
    ],
  },
}

export { swedenGeoPolygonFeature }
