// trail status report

import { Controller } from "stimulus"

import Stimulus from "../../../common/utils/stimulus"

class SitePageTsr extends Controller {
  static values = { fields: Boolean, datetime: Boolean, comment: Boolean }

  static targets = [`fields`, `status`, `datetime`]

  connect() {
    this.setStatus()
  }

  setStatus() {
    const value = this.statusTargets.find((t) => t.checked)?.value
    this.fieldsValue = !!value
    this.datetimeValue = value == `custom`
    this.commentValue = [`custom`, `recently`, `closed`].includes(value)
  }
}

Stimulus.register(`site-page-tsr`, SitePageTsr)
