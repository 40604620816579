import { Controller } from "stimulus"
import Cookies from "js-cookie"

import Stimulus from "../../common/utils/stimulus"

class NativeApp extends Controller {
  static targets = [`open`]
  static values = {
    active: Boolean,
    key: String,
    iosUrl: String,
    androidUrl: String,
    customProtocol: String,
  }

  #cookieName = `native-app-${this.keyValue}`
  #isIos = /(iPhone|iPad|iPod)/.test(window.navigator.userAgent)
  #isAndroid = /(Android)/.test(window.navigator.userAgent)

  connect() {
    if (
      ((this.#isIos && this.hasIosUrlValue) ||
        (this.#isAndroid && this.hasAndroidUrlValue)) &&
      !Cookies.get(this.#cookieName)
    ) {
      if (this.hasOpenTarget) {
        this.openTarget.href = this.#isIos
          ? this.iosUrlValue
          : this.androidUrlValue
      }

      this.activeValue = true
    }
  }

  open(e) {
    if (this.customProtocolValue) {
      e.preventDefault()
      window.location.href = this.customProtocolValue

      if (this.#isIos)
        window.setTimeout(
          () => (window.location.href = this.openTarget.href),
          1
        )
      else
        window.setTimeout(
          () => window.open(this.openTarget.href, `_blank`),
          200
        )
    }
  }

  close() {
    this.activeValue = false
    Cookies.set(this.#cookieName, true, { expires: 30 })
  }
}

Stimulus.register(`native-app`, NativeApp)
