import { Controller } from "stimulus"

import Stimulus from "../../../common/utils/stimulus"
import modal from "../../../common/utils/modal"
import { fetchSiteApi } from "../../../common/utils/site-api"

// create (list) modal

class SitePageSaveCreateModal extends Controller {
  static values = { listId: String }
  static targets = [`form`, `button`]

  connect() {
    document.documentElement.addEventListener(
      `turbo:submit-end`,
      this.turboSubmitEnd
    )
  }

  disconnect() {
    document.documentElement.removeEventListener(
      `turbo:submit-end`,
      this.turboSubmitEnd
    )
  }

  submit = () => {
    this.formTarget.requestSubmit()
  }

  disableButton() {
    this.buttonController.setDisabled(true)
  }

  turboSubmitEnd = (e) => {
    if (e.target != this.formTarget) return

    this.buttonController.setDisabled(false)

    if (e.detail.formSubmission.result.success) {
      this.formTarget.reset()
      modal.show(this.listIdValue)
    }
  }

  get buttonController() {
    return this.application.getControllerForElementAndIdentifier(
      this.buttonTarget,
      `button`
    )
  }
}

Stimulus.register(`site-page-save-create-modal`, SitePageSaveCreateModal)

// lists

class SitePageSaveLists extends Controller {
  static values = { url: String }
  static targets = [`input`]

  connect() {
    this.toggleSitePageToolbarSaveButton()
  }

  toggle(e) {
    const url = e.target.checked
      ? e.target.dataset.urlSave
      : e.target.dataset.urlUnsave

    fetchSiteApi({
      url,
      method: `PATCH`,
    })

    this.toggleSitePageToolbarSaveButton()
  }

  toggleSitePageToolbarSaveButton() {
    document
      .querySelectorAll(`[data-controller="site-page-toolbar"]`)
      .forEach((element) => {
        const controller =
          this.application.getControllerForElementAndIdentifier(
            element,
            `site-page-toolbar`
          )

        if (controller)
          controller.toggleSave(!!this.inputTargets.find((i) => i.checked))
      })
  }
}

Stimulus.register(`site-page-save-lists`, SitePageSaveLists)
