// TODO: fix modal on Escape press interaction with map

import { Controller } from "stimulus"
import { debounce, uniqWith } from "lodash"

import Stimulus from "../utils/stimulus"
import modal from "../utils/modal"
import { fetchSiteApi } from "../utils/site-api"

// search

class StopsForm extends Controller {
  static values = {
    modalId: String,
    locateUrl: String,
    suggestUrl: String,
  }

  static targets = [
    `startId`,
    `startInput`,
    `startOptions`,
    `finishId`,
    `finishInput`,
    `finishOptions`,
    `locateButton`,
    `submitButton`,
    `templateOptionsTip`,
    `templateOptionsLoader`,
    `templateOptionsEmpty`,
  ]

  static outlets = [`map-new`]

  #cancelInputBlurOnce = false

  connect() {
    // document.addEventListener(`click`, this.docClick)
    // document.addEventListener(`keyup`, this.docKeyUp)
    window.setTimeout(this.toggleButton, 50)
  }

  // disconnect() {
  //   document.removeEventListener(`click`, this.docClick)
  //   document.removeEventListener(`keyup`, this.docKeyUp)
  // }

  // docClick = (e) => {
  //   if (
  //     this.startOptionsTarget.ariaExpanded &&
  //     !this.startOptionsTarget.contains(e.target)
  //   )
  //     this.startOptionsTarget.ariaExpanded = false
  //   if (
  //     this.finishOptionsTarget.ariaExpanded &&
  //     !this.finishOptionsTarget.contains(e.target)
  //   )
  //     this.finishOptionsTarget.ariaExpanded = false
  // }

  // docKeyUp = (e) => {
  //   if (e.key == `Escape` && this.element.contains(document.activeElement)) {
  //     this.startOptionsTarget.ariaExpanded = false
  //     this.finishOptionsTarget.ariaExpanded = false
  //     if (
  //       ![this.startInputTarget, this.finishInputTarget].includes(
  //         document.activeElement
  //       )
  //     )
  //       this.submitButtonTarget.focus()
  //   }
  // }

  submit(e) {
    if (!this.startId || !this.finishId) {
      e.preventDefault()
      if (!this.startId) this.startInputTarget.value = ``
      else this.finishInputTarget.value = ``
      this.toggleButton()
      this.formTarget.requestSubmit()
    } else {
      e.preventDefault()
      modal.show(this.modalIdValue)
    }
  }

  locate() {
    this.locateButtonController?.setLoading(true)

    window.navigator.geolocation.getCurrentPosition(
      (position) => {
        fetchSiteApi({
          url: this.locateUrlValue,
          method: `POST`,
          body: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        })
          .then((r) => r.json())
          .then((data) => {
            this.startInputTarget.value = data.name
            this.startIdTarget.value = data.id
            this.toggleButton()
          })
          .catch(() => {
            modal.show(`global-error-modal`)
          })
          .finally(() => {
            this.locateButtonController?.setLoading(false)
          })
      },
      (error) => {
        this.locateButtonController?.setLoading(false)
        modal.show(`global-error-modal`)
      }
    )
  }

  inputChange(e) {
    this.suggest(e)
  }

  inputFocus(e) {
    const isStart = e.target === this.startInputTarget
    const optionsTarget = isStart
      ? this.startOptionsTarget
      : this.finishOptionsTarget

    e.target.value = ``
    optionsTarget.innerHTML = this.templateOptionsTipTarget.innerHTML
    optionsTarget.ariaExpanded = true
  }

  inputBlur = debounce((e) => {
    // is debounced to allow selectOption to be fired on click
    if (this.#cancelInputBlurOnce) {
      this.#cancelInputBlurOnce = false
      return
    }

    const isStart = e.target === this.startInputTarget
    const optionsTarget = isStart
      ? this.startOptionsTarget
      : this.finishOptionsTarget
    const idTarget = isStart ? this.startIdTarget : this.finishIdTarget
    const inputTarget = isStart ? this.startInputTarget : this.finishInputTarget

    optionsTarget.ariaExpanded = false
    inputTarget.value = idTarget.dataset.thisName || ``
  }, 200)

  suggest = debounce((e) => {
    const query = e.target.value.trim()
    const isStart = e.target === this.startInputTarget
    const optionsTarget = isStart
      ? this.startOptionsTarget
      : this.finishOptionsTarget

    if (!query) {
      optionsTarget.innerHTML = this.templateOptionsTipTarget.innerHTML
      return
    }

    optionsTarget.innerHTML = this.templateOptionsLoaderTarget.innerHTML

    fetchSiteApi({
      url: this.suggestUrlValue,
      method: `POST`,
      body: { query },
    })
      .then((r) => r.json())
      .then((data) => {
        const options = uniqWith(
          data.filter((d) => {
            if (
              (isStart && this.finishId == d.id) ||
              (!isStart && this.startId == d.id)
            )
              return false
            return true
          }),
          (a, b) => a.source_id == b.source_id && a.name == b.name
        ).map((d) => {
          const option = document.createElement(`li`)
          option.innerHTML = d.detailed_name
          option.dataset.id = d.id
          option.dataset.name = d.name
          option.dataset.type = isStart ? `start` : `finish`
          option.dataset.action = `click->stops-form#selectOption`
          option.tabIndex = 0
          option.ariaSelected = false
          option.role = `option`
          return option
        })

        if (options.length) optionsTarget.replaceChildren(...options)
        else optionsTarget.innerHTML = this.templateOptionsEmptyTarget.innerHTML
      })
      .catch(() => {
        optionsTarget.innerHTML = this.templateOptionsEmptyTarget.innerHTML
      })
  }, 400)

  toggleButton = () => {
    if (this.submitButtonController) {
      if (this.startInputTarget.value && this.finishInputTarget.value) {
        this.submitButtonController.setFillColor(`sea-green`)
      } else {
        this.submitButtonController.setFillColor(
          this.submitButtonTarget.dataset.thisDefaultFill
        )
      }
    }
  }

  selectOption(e) {
    this.#cancelInputBlurOnce = true

    const { dataset } = e.target

    const idTarget =
      dataset.type == `start` ? this.startIdTarget : this.finishIdTarget
    const inputTarget =
      dataset.type == `start` ? this.startInputTarget : this.finishInputTarget
    const optionsTarget =
      dataset.type == `start`
        ? this.startOptionsTarget
        : this.finishOptionsTarget

    idTarget.value = dataset.id
    idTarget.dataset.thisName = dataset.name
    inputTarget.value = dataset.name
    optionsTarget.ariaExpanded = false
    this.toggleButton()
  }

  switch() {
    const startInputValue = this.startInputTarget.value
    const startIdValue = this.startIdTarget.value
    this.startInputTarget.value = this.finishInputTarget.value
    this.startIdTarget.value = this.finishIdTarget.value
    this.finishInputTarget.value = startInputValue
    this.finishIdTarget.value = startIdValue
  }

  get startId() {
    return this.startIdTarget.value
  }

  get finishId() {
    return this.finishIdTarget.value
  }

  get submitButtonController() {
    return this.application.getControllerForElementAndIdentifier(
      this.submitButtonTarget,
      `button`
    )
  }

  get locateButtonController() {
    return this.application.getControllerForElementAndIdentifier(
      this.locateButtonTarget,
      `button`
    )
  }
}

Stimulus.register(`stops-form`, StopsForm)

// modal

class StopsModal extends Controller {
  static outlets = [`stops-form`, `map-new`]

  mapNewOutletConnected(mapNewOutlet) {
    mapNewOutlet.addReceiveHandler(this.mapReceive)
  }

  mapNewOutletDisconnected(mapNewOutlet) {
    mapNewOutlet.removeReceiveHandler(this.mapReceive)
  }

  mapReceive = (message) => {
    switch (message.method) {
      case `loaded`: {
        const { startId, finishId } = this.stopsFormOutlet
        this.mapNewOutlet.send(`setTraffic`, { startId, finishId })
        this.mapNewOutlet.send(`setFullscreenIfIsMobile`, true)
        break
      }

      case `closeRequested`: {
        this.modalController.hide()
        break
      }
    }
  }

  get modalController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      `modal`
    )
  }
}

Stimulus.register(`stops-modal`, StopsModal)
