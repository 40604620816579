import { Controller } from "stimulus"
import loadScript from "@outdoormap/frontend-utils/js/load-script"

import Stimulus from "../utils/stimulus"

class MapNew extends Controller {
  static values = { script: String }
  #observer = null
  #receiveHandlers = new Set()
  #defaultQuery = ``

  connect() {
    this.#defaultQuery = this.element.dataset.naturkartanQuery

    this.#observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        this.#observer.disconnect()
        this.load()
      }
    })
    this.#observer.observe(this.element)

    this.element.addEventListener(`naturkartanReceive`, this.receive)
  }

  disconnect() {
    this.#observer.disconnect()
    this.element.removeEventListener(`naturkartanReceive`, this.receive)
  }

  load = () => {
    if (window.NATURKARTAN_MAP) window.NATURKARTAN_MAP.inject(this.element)
    else {
      loadScript(this.scriptValue).then(() =>
        window.NATURKARTAN_MAP.inject(this.element)
      )
    }
  }

  addReceiveHandler(fn) {
    this.#receiveHandlers.add(fn)
  }

  removeReceiveHandler(fn) {
    this.#receiveHandlers.delete(fn)
  }

  receive = (e) => {
    this.#receiveHandlers.forEach((fn) => fn(e.detail))

    switch (e.detail.method) {
      case `siteViewed`: {
        this.receivedSiteViewed(e.detail.data)
        break
      }
    }
  }

  send = (method, data) => {
    this.element.dispatchEvent(
      new CustomEvent(`naturkartanSend`, { detail: { method, data } })
    )
  }

  setFullscreen(value) {
    this.send(`setFullscreen`, value)

    return new Promise((resolve, reject) => window.setTimeout(resolve, 300))
  }

  setQuery(query) {
    this.send(`setQuery`, query)
  }

  setQueryWithDefault(query, { removeImportance = true } = {}) {
    let newQuery = [this.#defaultQuery, query].filter(Boolean).join(`&`)

    if (removeImportance)
      newQuery = newQuery
        .split(`&`)
        .filter((q) => !q.includes(`importance=`))
        .join(`&`)

    this.setQuery(newQuery)
  }

  receivedSiteViewed = (data) => {
    let url = data.url
    let pathname = ``

    try {
      const urlObj = new URL(url)
      pathname = urlObj.href.replace(urlObj.origin, ``)
    } catch (err) {
      //
    }

    if (!pathname) return // no valid URL received – site cannot be tracked

    const utmParams =
      (pathname.includes(`?`) ? `&` : `?`) +
      `utm_source=naturkartan&utm_medium=map` // should be synced with the ones on the map webapp

    url += utmParams
    pathname += utmParams

    // gtm
    // window.dataLayer?.push({
    //   event: `pageview`,
    //   pageview_url: url,
    //   pageview_title: data.title,
    // })

    // plausible
    window.plausible?.(`pageview`, { u: url })

    // matomo
    window._paq?.push([`setCustomUrl`, pathname])
    window._paq?.push([`setDocumentTitle`, data.title])
    window._paq?.push([`trackPageView`])
  }
}

Stimulus.register(`map-new`, MapNew)
