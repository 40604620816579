import { Controller } from "stimulus"

import Stimulus from "../../common/utils/stimulus"

class SearchPage extends Controller {
  static targets = [`frame`, `emptyInFrame`, `emptyState`]

  connect() {
    if (this.hasFrameTarget)
      document.documentElement.addEventListener(
        `turbo:frame-load`,
        this.turboFrameLoad
      )
  }

  turboFrameLoad = () => {
    if (this.emptyInFrameTargets.length >= this.frameTargets.length) {
      document.documentElement.removeEventListener(
        `turbo:frame-load`,
        this.turboFrameLoad
      )

      if (this.hasEmptyStateTarget)
        this.emptyStateTarget.classList.remove(`hidden`)
    }

    this.emptyInFrameTargets.forEach((f) => f.closest(`turbo-frame`).remove())
  }
}

Stimulus.register(`search-page`, SearchPage)
