import { Controller } from "stimulus"

import Stimulus from "../utils/stimulus"

class FormField extends Controller {
  static targets = [`input`, `label`]
  static classes = [`disabled`]

  inputTargetConnected = (target) => {
    const mutation = new MutationObserver(this.inputMutated)
    mutation.observe(target, { attributes: true })
  }

  inputMutated = (mutations) => {
    // only works properly with if contains only one input
    // to adapt for multiple inputs, loop through this.inputTargets and check their disabled values, disable the field if all of them are disabled, and enable when at least one of them is enabled
    mutations.forEach((mutation) => {
      if (mutation.attributeName == `disabled`) {
        this.element.classList.toggle(
          this.disabledClass,
          mutation.target.disabled
        )
      }
    })
  }

  toggleRequired = (required) => {
    if (this.hasInputTarget) {
      this.inputTargets.forEach((i) => {
        i.required = required
        i.ariaRequired = required
      })
    }

    if (this.hasLabelTarget) {
      const labelEl = this.labelTarget.querySelector(`label`)

      if (labelEl) {
        const requiredEl = labelEl.querySelector(`abbr`)

        if (required) {
          if (requiredEl) {
            requiredEl.classList.remove(`hidden`)
          } else {
            labelEl.insertAdjacentHTML(
              `beforeend`,
              ` ${labelEl.dataset.requiredHtml}`
            )
          }
        } else {
          if (requiredEl) {
            requiredEl.classList.add(`hidden`)
          }
        }
      }
    }
  }
}

Stimulus.register(`form-field`, FormField)
