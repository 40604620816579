import { Controller } from "stimulus"
import Cookies from "js-cookie"

import Stimulus from "../utils/stimulus"

class GlobalNotice extends Controller {
  static values = { cookieName: String }

  #TO = null

  disconnect() {
    window.clearTimeout(this.#TO)
  }

  close() {
    Cookies.set(this.cookieNameValue, true, { expires: 30 })
    this.element.remove()
  }
}

Stimulus.register(`global-notice`, GlobalNotice)
