import { Controller } from "stimulus"

import Stimulus from "../utils/stimulus"

class CardsCarousel extends Controller {
  static targets = [`carousel`, `navPrev`, `navNext`, `frame`]

  connect() {
    if (this.hasFrameTarget)
      document.documentElement.addEventListener(
        `turbo:frame-load`,
        this.turboFrameLoad
      )
  }

  disconnect() {
    if (this.hasFrameTarget)
      document.documentElement.removeEventListener(
        `turbo:frame-load`,
        this.turboFrameLoad
      )
  }

  turboFrameLoad = (e) => {
    if (e.target === this.frameTarget && !this.hasCarouselTarget)
      this.element.remove()
  }

  carouselTargetConnected() {
    window.setTimeout(() => {
      this.carouselController.addScrollEndHandler(this.scrollEnded)
      this.scrollEnded()
    }, 10)
  }

  next() {
    this.carouselController.next()
  }

  prev() {
    this.carouselController.prev()
  }

  scrollEnded = () => {
    this.navPrevTarget.disabled = false
    this.navNextTarget.disabled = false

    if (this.carouselController.isBeginning) this.navPrevTarget.disabled = true
    if (this.carouselController.isEnd) this.navNextTarget.disabled = true
  }

  get carouselController() {
    if (!this.hasCarouselTarget) return null

    return this.application.getControllerForElementAndIdentifier(
      this.carouselTarget,
      `carousel`
    )
  }
}

Stimulus.register(`cards-carousel`, CardsCarousel)
