import { Controller } from "stimulus"

import Stimulus from "../utils/stimulus"

class Rating extends Controller {
  static values = { rate: Number, highlight: Number }
  static targets = [`input`, `button`]
  #parentForm

  connect() {
    this.#parentForm = this.element.closest(`form`)
    if (this.#parentForm)
      this.#parentForm.addEventListener(`reset`, this.parentFormReset)
  }

  diconnect() {
    if (this.#parentForm)
      this.#parentForm.removeEventListener(`reset`, this.parentFormReset)
  }

  rate(e) {
    const { index } = e.target.dataset
    this.inputTarget.value = index
    this.rateValue = index
    this.highlightValue = 0
    this.buttonTargets.forEach((b, i) => (b.ariaSelected = i + 1 == index))
  }

  highlight(e) {
    this.highlightValue = e.target.dataset.index
  }

  unhighlight(e) {
    this.highlightValue = 0
  }

  parentFormReset = () => {
    this.rateValue = 0
    this.highlightValue = 0
  }
}

Stimulus.register(`rating`, Rating)
