import { Controller } from "stimulus"
import Hls from "hls.js"

import Stimulus from "../utils/stimulus"

class Hero extends Controller {
  static targets = [`video`]
  static values = { videoActive: Boolean, videoType: String }

  connect() {
    if (this.hasVideoTarget) this.initVideo()
  }

  initVideo = () => {
    this.videoTarget.addEventListener(`canplaythrough`, this.videoCanPlay)

    if (
      this.videoTypeValue == `hls` &&
      !this.videoTarget.canPlayType(`application/vnd.apple.mpegurl`) &&
      Hls.isSupported()
    ) {
      const hls = new Hls()
      hls.loadSource(this.videoTarget.src)
      hls.attachMedia(this.videoTarget)
    }
  }

  videoCanPlay = () => {
    this.videoTarget.removeEventListener(`canplaythrough`, this.videoCanPlay)
    this.videoActiveValue = true
  }
}

Stimulus.register(`hero`, Hero)
