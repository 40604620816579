import { Controller } from "stimulus"

import Stimulus from "../../common/utils/stimulus"

class ContentsPage extends Controller {
  static values = { mobileMapActive: Boolean }
  static targets = [`map`]
  #enterMapFullscreenOnLoad = false

  mapTargetConnected() {
    window.setTimeout(() => {
      this.mapController.addReceiveHandler(this.mapReceive)
    }, 10)
  }

  showMobileMap() {
    this.mobileMapActiveValue = true

    if (this.mapTarget.dataset.naturkartanInited) this.enterMapFullscreen()
    else this.#enterMapFullscreenOnLoad = true
  }

  mapReceive = (data) => {
    switch (data.method) {
      case `loaded`: {
        if (this.#enterMapFullscreenOnLoad) {
          this.#enterMapFullscreenOnLoad = false
          this.enterMapFullscreen()
        }
        break
      }

      case `setFullscreen`: {
        this.mobileMapActiveValue = data.active
        break
      }
    }
  }

  enterMapFullscreen = () => {
    if (this.mapController) this.mapController.setFullscreen(true)
  }

  get mapController() {
    if (!this.hasMapTarget) return null

    return this.application.getControllerForElementAndIdentifier(
      this.mapTarget,
      `map-new`
    )
  }
}

Stimulus.register(`contents-page`, ContentsPage)
