import { Controller } from "stimulus"
import throttle from "raf-throttle"

import Stimulus from "../utils/stimulus"

class OverflowMask extends Controller {
  static values = { edges: String }
  #scrollHandler = null
  #resizeHandler = null

  connect() {
    this.element.classList.add(`overflow-mask`)
    this.#scrollHandler = throttle(this.setEdges)
    this.#resizeHandler = throttle(this.setEdges)

    this.element.addEventListener(`scroll`, this.#scrollHandler, {
      passive: true,
    })

    window.addEventListener(`resize`, this.#resizeHandler, { passive: true })

    this.setEdges()
  }

  disconnect() {
    this.element.removeEventListener(`resize`, this.#scrollHandler, {
      passive: true,
    })

    window.removeEventListener(`resize`, this.#resizeHandler, { passive: true })
  }

  setEdges = () => {
    const edges = []
    const el = this.element

    if (el.scrollWidth > el.offsetWidth) {
      if (el.scrollLeft > 5) edges.push(`left`)
      if (el.scrollLeft + el.offsetWidth <= el.scrollWidth - 5)
        edges.push(`right`)
    }

    if (el.scrollHeight > el.offsetHeight) {
      if (el.scrollTop > 5) edges.push(`top`)
      if (el.scrollTop + el.offsetHeight <= el.scrollHeight - 5)
        edges.push(`bottom`)
    }

    this.edgesValue = edges
  }
}

Stimulus.register(`overflow-mask`, OverflowMask)
