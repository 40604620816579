import { Controller } from "stimulus"
import Hls from "hls.js"

import Stimulus from "../utils/stimulus"

class Media extends Controller {
  static values = { hlsUrl: String }

  connect() {
    this.initHls()
  }

  initHls = () => {
    if (!this.hlsUrlValue) return

    window.fetch(this.hlsUrlValue).then((response) => {
      if (response.ok) {
        if (this.element.canPlayType(`application/vnd.apple.mpegurl`)) {
          this.element.src = this.hlsUrlValue
        } else if (Hls.isSupported()) {
          const hls = new Hls()
          hls.loadSource(this.hlsUrlValue)
          hls.attachMedia(this.element)
        }
        this.element.preload = `auto`
      } else {
        this.element.preload = `metadata`
      }
    })
  }
}

Stimulus.register(`media`, Media)
