import { Controller } from "stimulus"

import Stimulus from "../utils/stimulus"
import { debounce } from "lodash"

class Carousel extends Controller {
  static values = { adaptiveHeight: Boolean }
  static targets = [`item`]
  #scrollEndHandlers = []
  #adaptiveHeightIO = null
  #adaptiveHeightMO = null
  #adaptiveHeightTargets = new Set()

  connect() {
    this.element.scrollLeft = 0

    if (this.adaptiveHeightValue) {
      this.#adaptiveHeightIO = new IntersectionObserver(
        this.adaptiveHeightObservation,
        {
          rootMargin: `100% 0% 100% 0%`,
          threshold: 0.1,
        }
      )

      this.#adaptiveHeightMO = new MutationObserver(this.adaptHeight)

      this.itemTargets.forEach((i) => {
        this.#adaptiveHeightIO.observe(i)
        this.#adaptiveHeightMO.observe(i, {
          attributes: true,
          childList: true,
          subtree: true,
        })
      })
    }
  }

  disconnect() {
    if (this.adaptiveHeightValue) {
      this.#adaptiveHeightIO.disconnect()
      this.#adaptiveHeightMO.disconnect()
      this.#adaptiveHeightTargets.clear()
    }
  }

  prev() {
    this.scrollTo(`prev`)
  }

  next() {
    this.scrollTo(`next`)
  }

  addScrollEndHandler(fn) {
    this.#scrollEndHandlers.push(fn)
  }

  scrollTo = (direction) => {
    const items =
      direction == `next` ? this.itemTargets : this.itemTargets.reverse()

    items.every((item, i) => {
      const listRect = this.element.getBoundingClientRect()
      const itemRect = item.getBoundingClientRect()
      const itemOffset = itemRect.left - listRect.left
      const itemMarginLeft = Math.abs(
        parseInt(window.getComputedStyle(item).marginLeft || 0)
      )

      if (
        (direction == `next` &&
          itemRect.width - itemMarginLeft > listRect.width - itemOffset) ||
        (direction == `prev` && Math.round(itemOffset) < 0)
      ) {
        item.scrollIntoView({ block: `nearest`, behavior: `smooth` })
        return false
      }

      return true
    })
  }

  adaptiveHeightObservation = (entries) => {
    // if (this.#adaptiveHeightMO) this.#adaptiveHeightMO.disconnect()
    // else this.#adaptiveHeightMO = new MutationObserver(this.adaptHeight)

    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // this.#adaptiveHeightMO.observe(entry.target, {
        //   attributes: true,
        //   childList: true,
        //   subtree: true,
        // })

        this.#adaptiveHeightTargets.add(entry.target)
      } else {
        this.#adaptiveHeightTargets.delete(entry.target)
      }
    })

    this.adaptHeight()
  }

  onScroll = debounce(() => this.#scrollEndHandlers.forEach((fn) => fn()), 100)

  adaptHeight = debounce(() => {
    const height = Math.max(
      ...[...this.#adaptiveHeightTargets].map((t) => t.offsetHeight)
    )
    this.element.style.setProperty(`--height`, `${height / 16}rem`)
  }, 500)

  get isBeginning() {
    return this.element.scrollLeft < 20
  }

  get isEnd() {
    return (
      this.element.scrollLeft + 20 >
      this.element.scrollWidth - this.element.getBoundingClientRect().width
    )
  }
}

Stimulus.register(`carousel`, Carousel)
