import { Controller } from "stimulus"
import { debounce } from "lodash"
import Fuse from "fuse.js"

import Stimulus from "../utils/stimulus"
import { searchTypesense } from "../utils/typesense"
import {
  fetchApiCategories,
  fetchApiGuides,
  fetchApiCounties,
  fetchApiMunicipalities,
} from "../utils/api"

const LIMIT = 10
const API_DATA = {}
let API_FETCHED = false

class Search extends Controller {
  static targets = [
    `input`,
    `list`,
    `templateLabel`,
    `templateLink`,
    `templateEmpty`,
    `templateSearchable`,
  ]

  static values = {
    active: Boolean,
    status: String, // initial|results
    searchables: Array,
  }

  connect() {
    document.addEventListener(`click`, this.docClick, { passive: true })
  }

  disconnect() {
    document.removeEventListener(`click`, this.docClick, { passive: true })
  }

  docClick = (e) => {
    if (this.activeValue && !this.element.contains(e.target))
      this.activeValue = false
  }

  activeValueChanged() {
    if (!this.inputValue) this.statusValue = `initial`
  }

  inputFocused() {
    if (this.inputValue) this.performSearch()
    else {
      this.fetchApiIfNot()
      this.activeValue = true
    }
  }

  performSearch() {
    this.activeValue = true

    if (this.inputValue) {
      this.statusValue = `results`
      this.search()
    } else this.statusValue = `initial`
  }

  search = debounce(() => {
    if (!this.inputValue || this.inputValue == this.searchedValue) return
    this.searchedValue = this.inputValue

    Promise.all([
      this.searchApi(this.inputValue),
      searchTypesense({
        text: this.inputValue,
        language: document.documentElement.lang,
        limit: LIMIT,
      }),
    ])
      .then(([api, ts]) => this.populateResults({ ...api, ...ts }))
      .catch(() => this.populateResults({})) // TODO: show/log error
  }, 200)

  populateResults = (results) => {
    this.clearLists()

    const allListTarget = this.listTargets.find((l) => l.dataset.name == `all`)
    let allListMarkup = ``
    let anyResults = false

    const emptyMarkup = this.templateEmptyTarget.innerHTML.replaceAll(
      `{{TEXT}}`,
      this.inputValue
    )

    let emptyNestedSearchables = []

    const searchables = this.searchablesValue
      .filter((s) => s.name != `all`)
      .map((s) =>
        s.searchables
          ? s.searchables.map((se) => {
              emptyNestedSearchables.push(s.name)
              return { ...se, __nested__: s.name }
            })
          : s
      )
      .flat()

    searchables.forEach((searchable) => {
      const searchableResults = results[searchable.name]

      const searchableListTarget = this.listTargets.find(
        (l) => l.dataset.name == (searchable.__nested__ || searchable.name)
      )

      if (!searchableResults || !searchableResults.length) {
        if (!searchable.__nested__) searchableListTarget.innerHTML = emptyMarkup
        return
      }

      anyResults = true

      if (searchable.__nested__)
        emptyNestedSearchables = emptyNestedSearchables.filter(
          (s) => s != searchable.__nested__
        )

      const linkMarkup = this.templateLinkTargets
        .find(
          (s) => s.dataset.name == (searchable.__nested__ || searchable.name)
        )
        .innerHTML.replaceAll(`{{TEXT}}`, this.inputValue)

      const labelMarkup = this.templateLabelTargets
        .find((s) => s.dataset.name == searchable.name)
        .innerHTML.replaceAll(`{{TITLE}}`, searchable.title)
        .replaceAll(`{{TEXT}}`, this.inputValue)

      const templateResult = this.templateSearchableTargets.find(
        (s) => s.dataset.name == searchable.name
      ).innerHTML

      const resultsMarkups = searchableResults.map((r) =>
        templateResult
          .replaceAll(`{{TITLE}}`, r.title)
          .replaceAll(
            `{{URL}}`,
            (r.url || ``).replace(
              /^\/(\w{2})\//,
              `/${document.documentElement.lang}/`
            )
          )
          .replaceAll(`{{SLUG}}`, r.slug)
          .replaceAll(`{{ID}}`, r.id)
      )

      allListMarkup += labelMarkup + resultsMarkups.slice(0, LIMIT / 2).join(``)

      if (searchable.__nested__) {
        const nestedIndex = searchables
          .filter((s) => s.__nested__ == searchable.__nested__)
          .findIndex((s) => s.name == searchable.name)

        // if (!nestedIndex) searchableListTarget.innerHTML = linkMarkup
        searchableListTarget.innerHTML +=
          ((!nestedIndex && linkMarkup) || ``) +
          labelMarkup +
          resultsMarkups.join(``)
      } else
        searchableListTarget.innerHTML = linkMarkup + resultsMarkups.join(``)
    })

    if (!anyResults) allListTarget.innerHTML = emptyMarkup
    else {
      const allLinkMarkup = this.templateLinkTargets
        .find((s) => s.dataset.name == `all`)
        .innerHTML.replaceAll(`{{TEXT}}`, this.inputValue)

      allListTarget.innerHTML = allLinkMarkup + allListMarkup
    }

    emptyNestedSearchables.forEach(
      (s) =>
        (this.listTargets.find((l) => l.dataset.name == s).innerHTML =
          emptyMarkup)
    )
  }

  clearLists = () => {
    this.listTargets.forEach((t) => (t.innerHTML = ``))
  }

  searchApi = (text) => {
    return new Promise((resolve, reject) => {
      this.fetchApiIfNot()
        .then(() => {
          const results = {}

          Object.keys(API_DATA).forEach((name) => {
            results[name] = new Fuse(API_DATA[name], {
              keys: [`title`, `aliases`],
              threshold: 0.3,
              includeMatches: false,
            })
              .search(text)
              .slice(0, LIMIT)
              .map((f) => f.item)
          })

          return resolve(results)
        })
        .catch(reject)
    })
  }

  fetchApiIfNot = () => {
    // @LATERDO: implement a solution for case when `fetchApiIfNot` is called during the fetch process
    return new Promise((resolve, reject) => {
      if (API_FETCHED) return resolve()

      return Promise.all([
        fetchApiCategories(),
        fetchApiGuides(),
        fetchApiMunicipalities(),
        fetchApiCounties(),
      ])
        .then(([categories, guides, municipalities, counties]) => {
          API_DATA.categories = categories
          API_DATA.guides = guides
          API_DATA.municipalities = municipalities
          API_DATA.counties = counties
          API_FETCHED = true
          return resolve()
        })
        .catch(reject)
    })
  }

  doFocusInput() {
    window.setTimeout(() => this.inputTarget.focus(), 100)
  }

  get inputValue() {
    return this.inputTarget.value.trim()
  }
}

Stimulus.register(`search`, Search)
