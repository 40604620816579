import { Controller } from "stimulus"

import Stimulus from "../utils/stimulus"
import { fetchSiteApi } from "../utils/site-api"
import modal from "../utils/modal"

class Comment extends Controller {
  static targets = [
    `text`,
    `edit`,
    `editInput`,
    `form`,
    `answers`,
    `answersAnswer`,
    `actions`,
  ]

  static values = {
    id: Number,
    answers: Boolean,
    form: Boolean,
    locked: Boolean,
    reported: Boolean,
    edit: Boolean,
    reportText: String,
    reportUrl: String,
    deleteText: String,
    deleteUrl: String,
    lockUrl: String,
    hideModalIdOnDelete: String,
    removeDomIdOnDelete: String,
  }

  connect() {
    document.documentElement.addEventListener(
      `turbo:submit-end`,
      this.turboSubmitEnd
    )

    document.documentElement.addEventListener(
      `turbo:frame-load`,
      this.turboFrameLoad
    )
  }

  disconnect() {
    document.documentElement.removeEventListener(
      `turbo:submit-end`,
      this.turboSubmitEnd
    )

    document.documentElement.removeEventListener(
      `turbo:frame-load`,
      this.turboFrameLoad
    )
  }

  setText = (text) => {
    this.textTarget.innerHTML = text
  }

  form() {
    this.formValue = !this.formValue
  }

  answers() {
    this.answersValue = !this.answersValue

    if (!this.answersValue)
      window.setTimeout(
        () =>
          this.element.scrollIntoView({ behavior: `smooth`, block: `nearest` }),
        400
      )
  }

  edit() {
    this.editValue = !this.editValue
  }

  delete() {
    if (window.confirm(this.deleteTextValue))
      fetchSiteApi({ url: this.deleteUrlValue, method: `DELETE` }).then(() => {
        if (this.removeDomIdOnDeleteValue)
          document.getElementById(this.removeDomIdOnDeleteValue)?.remove()

        if (this.hideModalIdOnDeleteValue)
          modal.hide(this.hideModalIdOnDeleteValue)
        else if (this.parentTurboFrame) this.parentTurboFrame.reload()
      })
  }

  report() {
    if (window.confirm(this.reportTextValue)) {
      this.reportedValue = true
      fetchSiteApi({ url: this.reportUrlValue, method: `POST` })
    }
  }

  lock() {
    this.lockedValue = !this.lockedValue
    fetchSiteApi({ url: this.lockUrlValue, method: `POST` })
      .then((r) => r.json())
      .then((d) => (this.lockedValue = d.locked))
  }

  turboSubmitEnd = (e) => {
    if (
      this.hasFormTarget &&
      this.formTarget === e.target &&
      e.detail.formSubmission.result.success
    ) {
      if (this.parentTurboFrame) {
        this.parentTurboFrame.dataset.activateId = this.idValue
        this.formValue = false
        this.parentTurboFrame.reload()
      }
    }

    if (
      this.hasEditTarget &&
      this.editTarget === e.target &&
      e.detail.formSubmission.result.success
    ) {
      this.setText(this.editInputTarget.value)
      this.editValue = false
    }
  }

  turboFrameLoad = () => {
    if (
      this.parentTurboFrame &&
      this.parentTurboFrame.dataset.activateId == this.idValue
    ) {
      delete this.parentTurboFrame.dataset.activateId
      this.answersValue = true

      if (this.hasAnswersAnswerTarget)
        window.setTimeout(
          () =>
            this.answersAnswerTargets.at(-1).scrollIntoView({
              behavior: `smooth`,
              block: `nearest`,
            }),
          400
        )
    }
  }

  answersValueChanged() {
    if (this.hasActionsTarget)
      this.actionsTarget.dataset.answers = this.answersValue

    if (this.hasAnswersTarget)
      this.answersTarget.dataset.active = this.answersValue
  }

  formValueChanged() {
    if (this.hasActionsTarget) this.actionsTarget.dataset.form = this.formValue
    if (this.hasFormTarget) this.formTarget.dataset.active = this.formValue
  }

  reportedValueChanged() {
    if (this.hasActionsTarget)
      this.actionsTarget.dataset.reported = this.reportedValue
  }

  lockedValueChanged() {
    if (this.hasActionsTarget)
      this.actionsTarget.dataset.locked = this.lockedValue

    if (this.lockedValue && this.hasFormTarget) this.formValue = false
  }

  editValueChanged() {
    if (this.hasEditTarget) this.editTarget.dataset.active = this.editValue
    if (this.hasTextTarget) this.textTarget.dataset.hidden = this.editValue

    if (this.editValue && this.hasEditTarget)
      this.editTarget.scrollIntoView({ behavior: `smooth`, block: `nearest` })
  }

  get parentTurboFrame() {
    return this.element.closest(`turbo-frame`)
  }
}

Stimulus.register(`comment`, Comment)
