import { Controller } from "stimulus"
import { debounce } from "lodash"

import Stimulus from "../../common/utils/stimulus"
import { scrollIntoView } from "../../common/utils/scroll"

class ExplorePage extends Controller {
  static values = { adaptHeight: Boolean }
  static targets = [`map`]

  connect() {
    window.addEventListener(`resize`, this.adaptHeightDebounced)
    this.adaptHeight()
  }

  disconnect() {
    window.removeEventListener(`resize`, this.adaptHeightDebounced)
  }

  adaptHeight = () => {
    this.adaptHeightValue = false

    this.element.style.setProperty(
      `--top-position`,
      `${this.element.getBoundingClientRect().top}px`
    )

    this.adaptHeightValue = true
  }

  adaptHeightDebounced = debounce(this.adaptHeight, 200)

  mapTargetConnected() {
    window.setTimeout(() => {
      this.mapController.addReceiveHandler(this.mapReceive)
    }, 10)
  }

  mapReceive = (message) => {
    switch (message.method) {
      case `loaded`: {
        if (window.location.hash == `#${this.mapTarget.id}`)
          this.enterMapFullscreen()
        break
      }

      case `setQuery`: {
        this.mapQueryWasSet(message.data)
        break
      }
    }
  }

  enterMapFullscreen = (e) => {
    scrollIntoView(this.mapTarget, {
      behavior: `smooth`,
      block: `center`,
    }).then(() => this.mapController.setFullscreen(true))
  }

  mapQueryWasSet = (query) => {
    const urlTypes = this.mapTarget.dataset.urlTypes.split(`,`)
    const params = {}

    query.forEach((q) => {
      if (urlTypes.includes(q.type)) {
        params[q.type] = params[q.type] || []
        params[q.type].push(q.value)
      }
    })

    let url = Object.entries(params)
      .map(([k, v]) => `${k}=${v.join(`,`)}`)
      .join(`&`)

    if (url && this.mapTarget.id) url += `#${this.mapTarget.id}`

    window.history.replaceState(null, null, `?${url}`)
  }

  get mapController() {
    if (!this.hasMapTarget) return null

    return this.application.getControllerForElementAndIdentifier(
      this.mapTarget,
      `map-new`
    )
  }
}

Stimulus.register(`explore-page`, ExplorePage)
