import "@hotwired/turbo-rails" // the Turbo instance is automatically assigned to window.Turbo upon import
import { StreamActions } from "@hotwired/turbo"

import modal from "../utils/modal"
import { scrollIntoView } from "../utils/scroll"

const isDocScrollBehaviourSmooth =
  window.getComputedStyle(document.documentElement).scrollBehavior == `smooth`

// custom actions

StreamActions.modal_hide = function () {
  modal.hide(this.getAttribute(`modal_id`))
}

StreamActions.scroll_to_dom_id = function () {
  const domId = this.getAttribute(`dom_id`)
  if (domId)
    document
      .getElementById(domId)
      ?.scrollIntoView({ behavior: `smooth`, block: `start` })
}

StreamActions.reload_turbo_frame_dom_id = function () {
  const domId = this.getAttribute(`dom_id`)
  if (domId) document.getElementById(domId)?.reload()
}

// scroll to the top of the form (must have a DOM ID set) on Turbo submit end
// `data-turbo-disable-success-scroll` is a custom attr to flag forms which shouldn't be scrolled into view
// TODO: check how it behaves on in-modal forms

document.documentElement.addEventListener(`turbo:submit-end`, (e) =>
  window.setTimeout(() => {
    const formId = e.detail.formSubmission.formElement.id
    const formEl = formId ? document.getElementById(formId) : null

    if (formEl) {
      const failureEl = formEl.querySelector(`.form-failure`)
      const scrollIntoEl =
        failureEl || (!formEl.dataset.turboDisableSuccessScroll && formEl)

      if (scrollIntoEl) {
        scrollIntoView(scrollIntoEl, {
          behavior: `smooth`,
          block: `start`,
        }).then(() => {
          if (failureEl) {
            failureEl.tabIndex = 0
            failureEl.focus()
          }

          if (isDocScrollBehaviourSmooth) {
            document.documentElement.style.scrollBehavior = `unset`
          }
        })
      } else if (isDocScrollBehaviourSmooth) {
        document.documentElement.style.scrollBehavior = `unset`
      }
    }
  }, 100)
)

// document.addEventListener(`turbo:submit-end`, (e) => {
//   if (e.detail.fetchResponse.response.status != 422) return

//   const responseEl =
//     e.detail.formSubmission.formElement.querySelector(`.form-failure`)
//   if (!responseEl) return

//   try {
//     const reader = e.detail.fetchResponse.response.body.getReader()
//     reader.read().then(({ value }) => {
//       const decoded = new TextDecoder(`utf-8`).decode(new Uint8Array(value))
//       const json = JSON.parse(decoded)
//     })
//   } catch (err) {
//     //
//   }
// })

// Turbo + `object-fit` fix for Safari <= 15.3:
;(() => {
  const browserMatch = window.navigator.userAgent.match(
    /Version\/([0-9.]+) Safari/m
  )

  if (
    !browserMatch ||
    browserMatch.length != 2 ||
    parseFloat(browserMatch[1]) > 15.3
  )
    return

  const selectors = [`.guide-card__image img`, `.guide-page-header__title img`]

  const fix = () => {
    document.querySelectorAll(selectors.join(`,`)).forEach((img) => {
      const srcset = img.getAttribute(`srcset`)
      img.setAttribute(`srcset`, ``)
      img.setAttribute(`srcset`, srcset)
    })
  }

  document.addEventListener(`turbo:render`, fix)

  if (document.readyState === `loading`) {
    document.addEventListener(`DOMContentLoaded`, fix)
  } else {
    fix()
  }
})()

// Turbo wouldn't scroll to the top on page visit on Firefox if
// `scroll-behavior: smooth;` is set on `html`:
// https://github.com/hotwired/turbo/issues/426
// this is the fix:

if (isDocScrollBehaviourSmooth) {
  document.addEventListener(`turbo:load`, () => {
    document.documentElement.style.scrollBehavior = `smooth`
  })

  document.addEventListener(`turbo:before-visit`, () => {
    document.documentElement.style.scrollBehavior = `unset`
  })

  document.documentElement.addEventListener(`turbo:submit-start`, () => {
    document.documentElement.style.scrollBehavior = `unset`
  })
}
