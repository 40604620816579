import { Controller } from "stimulus"
import Cookies from "js-cookie"

import Stimulus from "../utils/stimulus"

class LocationTracker extends Controller {
  static targets = [`acceptButton`]

  static values = { name: String }

  accept() {
    this.button.setDisabled(true)

    window.navigator.geolocation.getCurrentPosition(
      (position) => {
        this.set(
          [position.coords.longitude, position.coords.latitude].join(`,`)
        )

        document
          .querySelectorAll(`.js--location-tracker-frame`)
          .forEach((f) => f.reload())
      },
      (error) => {
        this.button.setDisabled(false)
      }
    )
  }

  deny() {
    this.set(`deny`)
  }

  set = (value) => {
    Cookies.set(this.nameValue, value, { expires: 30 })
    this.element.classList.add(`hidden`)
  }

  get button() {
    return this.application.getControllerForElementAndIdentifier(
      this.acceptButtonTarget,
      `button`
    )
  }
}

Stimulus.register(`location-tracker`, LocationTracker)
