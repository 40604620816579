import { Controller } from "stimulus"
import Receiver from "@mcaptcha/core-glue"

import Stimulus from "../utils/stimulus"

class Mcaptcha extends Controller {
  static values = { url: String }
  static targets = [`input`]
  #receiver

  connect() {
    this.#receiver = new Receiver(
      { widgetLink: new URL(this.urlValue) },
      (token) => (this.inputTarget.value = token)
    )

    this.#receiver.listen()
  }

  disconnect() {
    this.#receiver?.destroy()
  }
}

Stimulus.register(`mcaptcha`, Mcaptcha)
