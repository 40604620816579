export default (fn) => {
  if (window.Turbo) {
    document.addEventListener(`turbo:load`, fn)
  } else {
    if (document.readyState === `loading`) {
      document.addEventListener(`DOMContentLoaded`, fn)
    } else {
      fn()
    }
  }
}
