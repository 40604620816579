import { Controller } from "stimulus"

import Stimulus from "../utils/stimulus"

class ShareModal extends Controller {
  static values = { url: String, copied: Boolean }
  static targets = [`input`]

  copyUrlToClipboard() {
    window.navigator.clipboard.writeText(this.urlValue)
    this.copiedValue = true
    window.setTimeout(() => (this.copiedValue = false), 2000)
  }

  selectInputText() {
    this.inputTarget.select()
  }
}

Stimulus.register(`share-modal`, ShareModal)
