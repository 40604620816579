import { Controller } from "stimulus"

import Stimulus from "../../../common/utils/stimulus"
import modal from "../../../common/utils/modal"

// form modal

class SitePageReviewsFormModal extends Controller {
  static values = { frameId: String, listId: String, failure: Boolean }
  static targets = [`form`, `button`, `failure`]

  connect() {
    document.documentElement.addEventListener(
      `turbo:submit-end`,
      this.turboSubmitEnd
    )
  }

  disconnect() {
    document.documentElement.removeEventListener(
      `turbo:submit-end`,
      this.turboSubmitEnd
    )
  }

  requestSubmit() {
    this.formTarget.requestSubmit()
  }

  formSubmit() {
    this.buttonController.setDisabled(true)
    this.failureValue = false
  }

  turboSubmitEnd = (e) => {
    if (e.target != this.formTarget) return

    this.buttonController.setDisabled(false)

    if (e.detail.formSubmission.result.success) {
      this.formTarget.reset()
      this.modalController.hide()
      modal.show(this.listIdValue)

      const listFrameEl = document.getElementById(this.frameIdValue)
      if (listFrameEl) listFrameEl.reload()
    } else this.failureValue = true
  }

  get buttonController() {
    return this.application.getControllerForElementAndIdentifier(
      this.buttonTarget,
      `button`
    )
  }

  get modalController() {
    return this.application.getControllerForElementAndIdentifier(
      this.element,
      `modal`
    )
  }
}

Stimulus.register(`site-page-reviews-form-modal`, SitePageReviewsFormModal)
