import { Controller } from "stimulus"

import Stimulus from "../../../common/utils/stimulus"
import { fetchSiteApi } from "../../../common/utils/site-api"

// activity

class SitePageActivity extends Controller {
  toggle(e) {
    const buttonController =
      this.application.getControllerForElementAndIdentifier(
        e.currentTarget,
        `button`
      )

    if (buttonController) buttonController.setDisabled(true)

    fetchSiteApi({
      url: e.params.url,
      method: `PATCH`,
    }).then(() => this.parentTurboFrame && this.parentTurboFrame.reload())
  }

  get parentTurboFrame() {
    return this.element.closest(`turbo-frame`)
  }
}

Stimulus.register(`site-page-activity`, SitePageActivity)

// activity modal

class SitePageActivityModal extends Controller {
  static targets = [`frame`, `addButton`, `removeButton`]

  add(e) {
    this.addButtonController.setDisabled(true)

    fetchSiteApi({
      url: e.params.url,
      method: `PATCH`,
    }).then(() => {
      this.addButtonController.setDisabled(false)
      this.addButtonTarget.classList.add(`hidden`)
      this.removeButtonTarget.classList.remove(`hidden`)
      this.frameTarget.reload()
      this.activitiesFrame.reload()
    })
  }

  remove(e) {
    this.removeButtonController.setDisabled(true)

    fetchSiteApi({
      url: e.params.url,
      method: `PATCH`,
    }).then(() => {
      this.removeButtonController.setDisabled(false)
      this.addButtonTarget.classList.remove(`hidden`)
      this.removeButtonTarget.classList.add(`hidden`)
      this.frameTarget.reload()
      this.activitiesFrame.reload()
    })
  }

  get addButtonController() {
    return this.application.getControllerForElementAndIdentifier(
      this.addButtonTarget,
      `button`
    )
  }

  get removeButtonController() {
    return this.application.getControllerForElementAndIdentifier(
      this.removeButtonTarget,
      `button`
    )
  }

  get activitiesFrame() {
    return document.querySelector(`.js--user-page-activities-frame`)
  }
}

Stimulus.register(`site-page-activity-modal`, SitePageActivityModal)
