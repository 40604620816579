import { Controller } from "stimulus"
import { debounce } from "lodash"

import Stimulus from "../utils/stimulus"

class Slideshow extends Controller {
  static targets = [`slides`, `item`, `navPrev`, `navNext`]
  static outlets = [`slideshow`]

  connect() {
    this.slidesTarget.scrollLeft = 0
    this.toggleNav()
  }

  slideshowOutletConnected() {
    window.setTimeout(
      () => this.slideshowOutlet.scrollToIndex(this.currentIndex),
      10
    )
  }

  prev() {
    this.scrollToDirection(`prev`)
  }

  next() {
    this.scrollToDirection(`next`)
  }

  scrollToDirection = (direction) => {
    const items =
      direction == `next` ? this.itemTargets : this.itemTargets.reverse()

    items.every((item, i) => {
      const listRect = this.slidesTarget.getBoundingClientRect()
      const itemRect = item.getBoundingClientRect()
      const itemOffset = itemRect.left - listRect.left
      const itemMarginLeft = Math.abs(
        parseInt(window.getComputedStyle(item).marginLeft || 0)
      )

      if (
        (direction == `next` &&
          itemRect.width - itemMarginLeft > listRect.width - itemOffset + 20) ||
        (direction == `prev` && Math.round(itemOffset) < 0)
      ) {
        item.scrollIntoView({ block: `nearest` })
        return false
      }

      return true
    })
  }

  scrollToIndex = (index) => {
    this.slidesTarget.style.setProperty(`scroll-behavior`, `auto`)
    this.itemTargets[index]?.scrollIntoView({
      block: `nearest`,
      behavior: `instant`,
    })
    this.slidesTarget.style.removeProperty(`scroll-behavior`)
  }

  toggleNav = () => {
    if (this.hasNavPrevTarget) this.navPrevTarget.disabled = this.isBeginning
    if (this.hasNavNextTarget) this.navNextTarget.disabled = this.isEnd
  }

  onScroll = debounce(this.toggleNav, 100)

  get currentIndex() {
    return Math.floor(
      this.slidesTarget.scrollLeft /
        this.slidesTarget.getBoundingClientRect().width
    )
  }

  get isBeginning() {
    return this.slidesTarget.scrollLeft < 20
  }

  get isEnd() {
    return (
      this.slidesTarget.scrollLeft + 20 >
      this.slidesTarget.scrollWidth -
        this.slidesTarget.getBoundingClientRect().width
    )
  }
}

Stimulus.register(`slideshow`, Slideshow)
