function scrollIntoView(element, options) {
  return new Promise((resolve) => {
    if (!(element instanceof Element)) {
      throw new TypeError(`Argument 1 must be an Element`)
    }

    let same = 0
    let lastPos = null
    const check = () => {
      const newPos = element.getBoundingClientRect().top

      if (newPos === lastPos) {
        if (same++ > 2) return resolve()
      } else {
        same = 0
        lastPos = newPos
      }

      return requestAnimationFrame(check)
    }

    element.scrollIntoView(options)
    requestAnimationFrame(check)
  })
}

export { scrollIntoView }
