import domReady from "../utils/dom-ready"
import { scrollIntoView } from "../utils/scroll"

// scrolls to the very first `.form-failure` is it exists after non-turbo form submissions

domReady(() => {
  window.setTimeout(() => {
    const el = document.querySelector(`.form-failure`)
    if (el) {
      scrollIntoView(el, {
        behavior: `smooth`,
        block: `center`,
      }).then(() => {
        el.tabIndex = 0
        el.focus()
      })
    }
  }, 100)
})
