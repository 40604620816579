const fetchSiteApi = ({ url, json = true, body = null, ...params }) => {
  const csrfToken = document.querySelector(`meta[name="csrf-token"]`).content

  return window.fetch(url, {
    body: body && json ? JSON.stringify(body) : body,
    headers: {
      "X-CSRF-Token": csrfToken,
      ...(json
        ? {
            "Content-Type": `application/json`,
            Accept: `application/json`,
          }
        : {}),
      ...(params?.headers || {}),
    },
    ...params,
  })
}

export { fetchSiteApi }
