import { Controller } from "stimulus"

import Stimulus from "../../../common/utils/stimulus"

class SitePageDescription extends Controller {
  static values = { expandable: Boolean, expanded: Boolean }

  static targets = [`text`]

  connect() {
    if (this.textTarget.offsetHeight >= this.textTarget.scrollHeight)
      this.expandableValue = false
  }

  expand() {
    this.expandedValue = true
  }

  collapse() {
    this.element.scrollIntoView({ behavior: `smooth`, block: `nearest` })
    this.expandedValue = false
  }
}

Stimulus.register(`site-page-description`, SitePageDescription)
